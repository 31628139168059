import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
    Col, Form, InputNumber, Row, Select
} from 'antd'
import { useClassName } from 'utils/cn'

import { actions, selectors } from '../../models'

import './style.less'
import { Button } from '../../components'
import { setCurDistributionCalculate } from '../../reducers/view/actions'
import request from './../../utils/request'

const {
    curCalculate,
    getRegionsCatalog,
    getRegionNormCultureCatalog,
    getSoilCompositionCatalog,
    curNutrition,
    getUser,
    isAuth,
    isCalculateCompleted
} = selectors

const {
    getCalculatorsNormRegionCulture,
    addNutrition,
    updateNutrition
} = actions

export default ({ setDistributionType }) => {
    const user = useSelector(state => getUser(state))
    const isAuthState = useSelector(state => isAuth(state))
    const calculator = useSelector(state => curCalculate(state))
    const nutrition = useSelector(state => curNutrition(state))
    // const regions = useSelector(state => getRegionsCatalog(state));
    const cultures = useSelector(state => getRegionNormCultureCatalog(state))
    const compositionSoil = useSelector(state => getSoilCompositionCatalog(state))
    const isCompleted = useSelector(state => isCalculateCompleted(state))
    const dispatch = useDispatch()
    const { t } = useTranslation('calculators')
    const cn = useClassName('calculator')
    const match = useRouteMatch()
    const { params: { id } } = match
    const [form] = Form.useForm()
    const [isFull, setIsFull] = useState(true)
    const [filteredRegions, setFilteredRegions] = useState([])
    const [regions, setRegions] = useState([])

    useEffect(() => {
        nutrition.region?.id
            && dispatch(getCalculatorsNormRegionCulture(`?filter={"region.id":${nutrition.region?.id}}`))
        form.setFieldsValue({
            planned_yield: nutrition.planned_yield,
            region: nutrition.region?.id,
            culture: nutrition.culture?.id,
            preceding_culture: nutrition.preceding_culture?.id,
            ph_salt: nutrition.ph_salt,
            mechanical_composition: nutrition.mechanical_composition?.id,
            no3: nutrition.no3,
            k2o: nutrition.k2o,
            p2o5: nutrition.p2o5,
            method: nutrition.method
        })
    }, [calculator, isAuthState])

    useEffect(() => {
        setFilteredRegions(regions.filter(item => item.visible))
    }, [user, regions])

    const sendData = () => {
        const values = form.getFieldsValue(true)
        dispatch(setCurDistributionCalculate('nutrition'))

        const updateValues = {
            planned_yield: values?.planned_yield || nutrition.planned_yield,
            region: values?.region || nutrition.region?.id,
            culture: values?.culture || nutrition.culture?.id,
            preceding_culture: values?.preceding_culture || nutrition.preceding_culture?.id,
            ph_salt: values?.ph_salt || nutrition.ph_salt,
            mechanical_composition: values?.mechanical_composition || nutrition.mechanical_composition?.id,
            no3: values?.no3 || nutrition.no3,
            k2o: values?.k2o || nutrition.k2o,
            p2o5: values?.p2o5 || nutrition.p2o5,
            method: values?.method || nutrition.method
        }
        nutrition.id
            ? dispatch(updateNutrition({ id: nutrition.id, values: updateValues }))
            : dispatch(addNutrition({
                calculate: {
                    id,
                    name: calculator.name,
                    calculator_type: calculator.calculator_type
                },
                values
            }))
    }

    const onFieldsChange = (field, fields) => {
        let isFull = true

        fields.forEach(field => {
            if (!field.value) isFull = false
        })
        setIsFull(isFull)
    }

    const onValuesChange = (value, values) => {
        if (Object.keys(value)[0] === 'region') {
            const filter = `?filter={"region.id":${value.region}}`
            dispatch(getCalculatorsNormRegionCulture(filter))
            form.setFieldsValue({ culture: '' })
        }
    }

    const filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase()
        inputName = inputName.toLowerCase()
        return optionName.indexOf(inputName) !== -1
    }

    const clearFields = () => {
        form?.resetFields()
    }

    useEffect(() => {
        updateRegions()
    }, [])

    const updateRegions = async () => {
        try {
            const responce = await request.get({
                url: `/api/v1/region/region/`,
                body: {
                    page_size: 100
                }
            })
            if (Array.isArray(responce.results)) {
                setRegions(responce.results)
            }
        } finally { }
    }

    return (
        <div>
            <Form onFieldsChange={onFieldsChange}
                onValuesChange={onValuesChange}
                form={form}
                className={cn()}
                name="calculator-params">
                <div className={cn('params')}>
                    <Row gutter={16}>
                        <Col span={7}>{t('planned yield t')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="planned_yield"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <InputNumber
                                    min={0}
                                    max={1000}
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="float"
                                    placeholder="" />
                            </Form.Item>
                        </Col>

                        <Col span={7}>{t('predecessor choice')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="preceding_culture"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <Select
                                    showSearch
                                    disabled={isCompleted}
                                    filterOption={filterOption}>
                                    {
                                        cultures.map(
                                            (item =>
                                            (
                                                <Select.Option value={item.culture?.id} key={item.culture?.id}>
                                                    {item.culture?.name}
                                                </Select.Option>
                                            )
                                            )
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={7}>{t('region type')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="region"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <Select
                                    showSearch
                                    disabled={isCompleted}
                                    filterOption={filterOption}>
                                    {
                                        regions.map(
                                            (item =>
                                            (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            )
                                            )
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={7}>{t('acidity type')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="ph_salt"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <InputNumber
                                    min={0}
                                    max={50}
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="float"
                                    placeholder="" />
                            </Form.Item>
                        </Col>

                        <Col span={7}>{t('culture choice')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="culture"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <Select
                                    showSearch
                                    disabled={isCompleted}
                                    filterOption={filterOption}>
                                    {
                                        cultures.map(
                                            (item =>
                                            (
                                                <Select.Option value={item.culture?.id} key={item.culture?.id}>
                                                    {item.culture?.name}
                                                </Select.Option>
                                            )
                                            )
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={7}>{t('soil texture choice')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="mechanical_composition"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <Select
                                    showSearch
                                    disabled={isCompleted}
                                    filterOption={filterOption}>
                                    {
                                        compositionSoil.map(
                                            (item => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            )
                                            )
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>{t('data of test')}</Col>
                        <Col span={7}>{t('data of test n')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="no3"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <InputNumber
                                    min={0}
                                    max={1000}
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="float"
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={7}>{t('data of test k')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="k2o"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <InputNumber
                                    min={0}
                                    max={1000}
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="float"
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={7}>{t('data of test p')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="p2o5"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <InputNumber
                                    min={0}
                                    max={1000}
                                    disabled={isCompleted}
                                    decimalSeparator=","
                                    validator="float"
                                    placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={7}>{t('method analysis')}</Col>
                        <Col span={5}>
                            <Form.Item
                                name="method"
                                rules={[{ required: true, message: t('not empty') }]}>
                                <Select
                                    disabled={isCompleted}
                                    filterOption={filterOption}>
                                    <Select.Option value="kirsanov">{t('kirsanov')}</Select.Option>
                                    <Select.Option value="chirikov">{t('chirikov')}</Select.Option>
                                    <Select.Option value="machigin">{t('machigin')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '15px' }}>
                        <Col>
                            <Button type="secondary" onClick={clearFields}>
                                {t('clear')}
                            </Button>
                        </Col>
                    </Row>

                    {!isFull && (
                        <Row className={cn('info')}>
                            <Col>
                                <div className={cn('info__inner')}>{t('info')}</div>
                            </Col>
                        </Row>
                    )}
                    {nutrition.id && (
                        <>
                            <div className="table-percent">
                                <div className="table-percent__title">{t('table title')}</div>
                                <Row className="table-percent__header">
                                    <Col span={4}>{t('table name')}</Col>
                                    <Col span={5}>{t('table autumn')}</Col>
                                    <Col span={5}>{t('table spring')}</Col>
                                    <Col span={5}>{t('table feeding')}</Col>
                                    <Col span={5}>{t('table total')}</Col>
                                </Row>
                                <Row className="table-percent__row">
                                    <Col span={4}>{t('table n')}</Col>
                                    <Col span={5}>{nutrition.recommended_n_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{nutrition.recommended_n_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{nutrition.recommended_n_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{(+nutrition.recommended_n_main + nutrition.recommended_n_sowing + nutrition.recommended_n_feeding)?.toFixed(1)}</Col>
                                </Row>
                                <Row className="table-percent__row">
                                    <Col span={4}>{t('table p')}</Col>
                                    <Col span={5}>{nutrition.recommended_p_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{nutrition.recommended_p_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{nutrition.recommended_p_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{(+nutrition.recommended_p_main + nutrition.recommended_p_sowing + nutrition.recommended_p_feeding)?.toLocaleString('ru-RU')}</Col>
                                </Row>
                                <Row className="table-percent__row">
                                    <Col span={4}>{t('table k')}</Col>
                                    <Col span={5}>{nutrition.recommended_k_main?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{nutrition.recommended_k_sowing?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{nutrition.recommended_k_feeding?.toLocaleString('ru-RU') ?? '-'}</Col>
                                    <Col span={5}>{(+nutrition.recommended_k_main + nutrition.recommended_k_sowing + nutrition.recommended_k_feeding)?.toLocaleString('ru-RU')}</Col>
                                </Row>
                            </div>
                        </>
                    )}
                    {!isCompleted && (
                        <Row className={cn('button')}>
                            <Col>
                                <Button
                                    type="primary"
                                    disabled={isCompleted}
                                    onClick={() => sendData()}
                                    size="large">
                                    {t('submit')}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </div>

            </Form>
        </div>
    )
}
