import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import Icon, { DiffOutlined } from '@ant-design/icons'
import { Button, Menu } from 'antd'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Link, withRouter } from 'react-router-dom'
import { logout } from '../../models/account/actions'
import { getUserName, getUser } from '../../models/account/selectors'
import { getDeterminantTypes } from '../../models/determinant-types/actions'
import { getRegions } from '../../models/regions/actions'
import { getCurDeterminantTypes, CurDeterminantTypesLoading } from '../../models/determinant-types/selectors'

import cn from '../../utils/cn'

import SampleLogIcon from '../../../public/images/sidebar/samplelog.svg'
import CalculatorIcon from '../../../public/images/sidebar/calculator.svg'
import DeterminantIcon from '../../../public/images/sidebar/determinant.svg'
import MapIcon from '../../../public/images/sidebar/map.svg'
import PivotIcon from '../../../public/images/sidebar/pivot.svg'
import LogoutIcon from '../../../public/images/sidebar/logout.svg'
import ProfileEditIcon from '../../../public/images/sidebar/profile-edit.svg'
import CatalogIcon from '../../../public/images/sidebar/catalog.svg'
import ProfileIcon from '../../../public/images/sidebar/profile.svg'
import './style.less'
import { DETERMINANTS } from '../../constants/determinats'

const { SubMenu } = Menu
const mstp = (state) => ({
    username: getUserName(state),
    user: getUser(state),
    loading: CurDeterminantTypesLoading(state),
    determinants: getCurDeterminantTypes(state)
})

const mdtp = ({
    logout,
    getRegions,
    getDeterminantTypes
})

@withRouter
@connect(mstp, mdtp)
@withTranslation('vertical menu')
@cn('menu')
export default class VMenu extends Component {
    componentDidMount () {
        const {
            getRegions,
            getDeterminantTypes
        } = this.props
        getRegions('?page_size=100')
        getDeterminantTypes('?filter={"is_committed":[true]}')
    }

    onLogout = () => {
        const { logout } = this.props
        logout()
    };

    render () {
        const { cn } = this
        const {
            t, username, user, history,
            determinants, loading, providedI
        } = this.props
        const { groups = [] } = user
        // const { i18n } = useTranslation('change lang');

        const changeLanguage = (lng) => {
            providedI.changeLanguage(lng)
        }

        const isAdmin = groups.includes('ROLE_ADMIN')
        const isLaborant = groups.includes('Laborant')
        const isMordovia = groups.includes('mordovia')
        const isFasie = groups.includes('fasie')
        if (loading) return <div />
        return (
            <div className={cn()}>
                <Menu mode="inline">
                    <SubMenu
                        id="m_profile"
                        key="user-profile"
                        title={(
                            <span>
                                <Icon component={ProfileIcon} />
                                <span>{username}</span>
                            </span>
                        )}>
                        <Menu.Item key="determinants" id="p_my_determinants">
                            <Link to="/determinants">
                                <Icon component={DeterminantIcon} />
                                {t('my determinant')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="calculators" id="p_my_calculators">
                            <Link to="/calculators">
                                <Icon component={CalculatorIcon} />
                                {t('my calculate')}
                            </Link>
                        </Menu.Item>
                        {isLaborant && (
                            <>
                                <Menu.Item key="registryies" id="p_my_registryies">
                                    <Link to="/mycological-registries">
                                        <Icon component={CalculatorIcon} />
                                        {t('mycological registryies')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="fedreg" id="p_fed_registry">
                                    <Link to="/fed-registries">
                                        <Icon component={CalculatorIcon} />
                                        {t('fed registryies')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="fsmreg" id="p_fsm_registry">
                                    <Link to="/fsm-registries">
                                        <Icon component={CalculatorIcon} />
                                        {t('fsm registryies')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="samples" id="p_my_samples">
                                    <Link to="/sample-log">
                                        <Icon component={SampleLogIcon} />
                                        {t('sample log')}
                                    </Link>
                                </Menu.Item>
                            </>
                        )}

                        {isLaborant && (
                            <>
                                <Menu.Item key="recommendations" id="p_my_recommendations">
                                    <Link to="/recommendation-registries">
                                        <Icon component={SampleLogIcon} />
                                        {t('recommendation registries')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="general_protocol" id="general_protocol">
                                    <Link to="/general-protocol">
                                        <Icon component={SampleLogIcon} />
                                        {t('general protocol')}
                                    </Link>
                                </Menu.Item>
                            </>
                        )}
                        {isMordovia && (
                            <Fragment>
                                {/* <Menu.Item key="pivot-table" id="p_pivot_table"> */}
                                {/*    <Link to="/pivot-table"> */}
                                {/*        <Icon component={PivotIcon} /> */}
                                {/*        {t('pivot table')} */}
                                {/*    </Link> */}
                                {/* </Menu.Item> */}
                                <SubMenu
                                    key="mordovia"
                                    id="p_mordovia_map"
                                    // onTitleClick={() => history.push('/mordovia-fields')}
                                    onTitleClick={() => history.push('/representation-fields')}
                                    title={(
                                        <span>
                                            <Icon component={MapIcon} />
                                            {t('my fields')}
                                        </span>
                                    )}>
                                    <Menu.Item key="accounting" id="p_accounting_documents">
                                        <Link to="/accounting-documents">
                                            {t('accounting documents')}
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                            </Fragment>
                        )}
                        <Menu.Item key="profile-edit" id="p_my_profile">
                            <Link to="/profile">
                                <Icon component={ProfileEditIcon} />
                                {t('profile edit')}
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        id="m_determinats"
                        onTitleClick={() => history.push('/determinants-info')}
                        key="determinant"
                        title={(
                            <span>
                                <Icon component={DeterminantIcon} />
                                <span>{t('determinant')}</span>
                            </span>
                        )}>
                        {determinants.map(item => (
                            <Menu.Item key={`determinant/${item.id}`} id={`det_${item.code}`}>
                                <Link to={`/determinant/${item.id}`}>
                                    {t(DETERMINANTS[item.name])}
                                </Link>
                            </Menu.Item>
                        ))}
                    </SubMenu>
                    <SubMenu
                        id="m_calculators"
                        onTitleClick={() => history.push('/calculators-info')}
                        key="calculator"
                        title={(
                            <span>
                                <Icon component={CalculatorIcon} />
                                <span>{t('calculate')}</span>
                            </span>
                        )}>
                        {isFasie && (
                            <Menu.Item key="calculator-rb" id="calc_rb">
                                <Link to="/calculator/5">
                                    {t('rb calculate')}
                                </Link>
                            </Menu.Item>
                        )}
                        <Menu.Item key="calculator-norm" id="calc_norm">
                            <Link to="/calculator/6">
                                {t('norm calculate')}
                            </Link>
                        </Menu.Item>
                        {!isFasie && (
                            <>
                                <Menu.Item key="calculator-fluid" id="calc_fluid">
                                    <Link to="/calculator-fluid">
                                        {t('fluid calculate')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="calculator-fed" id="calc_fed">
                                    <Link to="/calculator-fed">
                                        {t('fed calculate')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="calculator-agro" id="calc_agro">
                                    <Link to="/calculator/7">
                                        {t('agro calculate')}
                                    </Link>
                                </Menu.Item>
                                {/* <Menu.Item key="calculator-myco" id="calc_myco">
                                    <Link to="/calculator-myco">
                                        {t('myco calculate')}
                                    </Link>
                                </Menu.Item> */}
                                <Menu.Item key="calculator-nutr" id="calc_nutrition">
                                    <Link to="/calculator/11">
                                        {t('nutrition calculate')}
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="calculator-phyto" id="calc_phyto">
                                    <Link to="/calculator-phyto">
                                        {t('phyto calculate')}
                                    </Link>
                                </Menu.Item>
                            </>
                        )}
                    </SubMenu>
                    <SubMenu
                        id="m_catalogs"
                        onTitleClick={() => history.push('/catalog-info')}
                        key="catalog"
                        title={(
                            <span>
                                <Icon component={CatalogIcon} />
                                <span>{t('catalog')}</span>
                            </span>
                        )}>
                        <Menu.Item key="fertilizers" id="c_fertilizers">
                            <Link to="/fertilizers">
                                {t('fertilizers')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="diseases" id="c_diseases">
                            <Link to="/diseases">
                                {t('diseases')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="harmful-objects" id="c_harmful">
                            <Link to="/harmful-objects">
                                {t('harmful')}
                            </Link>
                        </Menu.Item>
                        {!isFasie && (
                            <Menu.Item key="pesticides" id="c_pesticides">
                                <Link to="/pesticides">
                                    {t('pesticides')}
                                </Link>
                            </Menu.Item>
                        )}
                    </SubMenu>
                    <Menu.Item key="profile-exit" onClick={this.onLogout} id="p_logout">
                        <Icon component={LogoutIcon} />
                        {t('profile exit')}
                    </Menu.Item>
                    <Menu.Item key="change-lang" onClick={this.onLogout} id="p_logout">
                        <Button style={{ width: '50%' }} onClick={() => changeLanguage('ru')}>ru</Button>
                        <Button style={{ width: '50%' }} onClick={() => changeLanguage('en')}>en</Button>
                    </Menu.Item>
                </Menu>
            </div>
        )
    }
}
